import 'whatwg-fetch';
import elasticlunr from 'elasticlunr';
let docStore;
let searchIndex;
function searchInit(window, document) {
  "use strict";
  const displayClass = 'd-block';
  const hideClass = 'd-none';

  const search = (e) => {
    if (e.target.value && e.target.value.length > 3) {
      const results = searchIndex.search(e.target.value, {
        bool: "OR",
        expand: true,
      });

      const resEl = document.getElementById("searchResults");
      const noResultsEl = document.getElementById("noResultsFound");

      resEl.innerHTML = "";
      if (results) {
        noResultsEl.classList.remove(displayClass);
        resEl.classList.add(displayClass);
        results.map((r) => {
          const { id, title, description } = docStore.getDoc(r.ref);
          const el = document.createElement("li");
          resEl.appendChild(el);

          const h3 = document.createElement("h3");
          el.appendChild(h3);

          const a = document.createElement("a");
          a.setAttribute("href", id);
          a.textContent = title;
          h3.appendChild(a);

          const p = document.createElement("p");
          p.textContent = description;
          el.appendChild(p);
        });
      } else {
        noResultsEl.classList.add(hideClass);
        resEl.classList.remove(hideClass);
      }
    }
  };

  fetch("/search-index.json")
    .then((response) => response.json())
    .then((rawIndex) => {
      searchIndex = elasticlunr.Index.load(rawIndex);
      docStore = searchIndex.documentStore;
      document.getElementById("searchField").addEventListener("input", search);
    });
};
export { searchInit };
export default searchInit;